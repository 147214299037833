import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

const Home = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <main id="main" className="main">
        <div className='row'>
          <div className='col-lg-12'>

          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-2'>
                    Welcome to the Exit Interview System
                  </div>

                  <div className='card-description'>
                    <p>
                      Exit interviews play a crucial role as the final step in the employee life cycle.
                      </p>
                    <p>
                      These interviews provide valuable insights into the employer and offer recommendations for improvements on various aspects, including the working environment, culture, processes and systems, management style, and development opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='card-description text-center'>
                    
                      <div className='row'>
                    <div className='col-lg-8 col-md-11 col-sm-9  mx-auto'>
                      <img className='fluid-image' alt="exit interview process" src="/assets/app/images/exitProcessFlow.png" />
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='card-description'>
                    <p>Actions Required</p>
                  </div>
                  <div className="row">
                    <div className="col-md-8 offset-md-2">
                      <div className='card exit-actions-card'>

                        <div className='card-header exit-actions-card-header'>
                          <b> Action Items - Mandatory Face to Face Interviews</b>
                        </div>
                        <div className='card-body'>

                          <div className='content-block mt-2  mx-4'>

                            <div className="list-group list-group-flush exit-actions">

                              <div className="list-group-item list-group-item-action" aria-current="true">
                                <p className="mb-1">
                                  <b>Joe Slovo</b> (Head: Corporate Finance)
                                </p>
                                <div className={"mt-2 mb-2"}>
                                   <span className={"completion-status px-3 pb-1 rounded-2 me-2"}>Manager completed questionnaire</span>
                                 </div>
                              </div>

                              <div className="list-group-item list-group-item-action" aria-current="true">
                                <p className="mb-1">
                                  <b>Anele Swanepoel</b> (Divisional Executive: Channel Support))
                                </p>
                                <div className={"mt-2 mb-2"}>
                                   <span className={"completion-status px-3 pb-1 rounded-2 me-2"}>Completed exitee questionnaire</span>
                                 </div>
                              </div>

                              <div className="list-group-item list-group-item-action" aria-current="true">
                                <div className={"d-flex justify-content-between mb-4"}>
                                  <span className="mb-0">
                                    <b>Sam Smith</b> (Executive: Risk Solutions)
                                  </span>
                                  <span className={"warning-status px-2 pb-0 rounded-2 me-2"}>
                                    <i className="fa-solid fa-triangle-exclamation"></i> Exit date in 2 days
                                  </span>
                                </div>

                                <div className={"mt-2 mb-2"}>

                                 </div>
                              </div>

                              <div className="list-group-item list-group-item-action" aria-current="true">
                                <p className="mb-4">
                                  <b>Nick White</b> (Chief Spec: Product Marketing)
                                </p>
                                <div className={"mt-2 mb-2"}>

                                 </div>
                              </div>

                              <div className="list-group-item list-group-item-action" aria-current="true">
                                <p className="mb-1">
                                  <b>Wendy Ross</b> (Senior Specialist: Product Owner)
                                </p>
                                <div className={"mt-2 mb-2"}>
                                   <span className={"completion-status px-3 pb-1 rounded-2 me-2"}>Completed exitee questionnaire</span>
                                   <span className={"completion-status px-3 pb-1 rounded-2"}>Manager completed questionnaire</span>
                                 </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>






                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default Home;
