import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data';
import InsightsFiltered from "./InsightsFiltered";
import Insightsoriginal from "./Insightsoriginal"; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const Demographics = () => {
  const [toggle, setToggle] = useState(false);
  const handleFilterChange = () => {
    setToggle(!toggle);
  }

  return (
    <>
      <Header icon={"fa-solid fa-users"} label={"Dashboards / Exit Insights"}/>
      <main id="main" className="main">
        <TempFilters handleFilterChange={handleFilterChange}/>
        <div className="d-flex my-3">
          <span className="me-2">Show from: </span>
          <select>
            <option value={1}>Jan '23</option>
          </select>
        </div>
        <br/>
        {toggle? <Insightsoriginal /> : <InsightsFiltered />}
      </main>
    </>
  )
}
export default Demographics;
