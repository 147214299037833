import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const LineManager = () => {
  // Define a state variable to store the textarea content
  const [radio4, setRadio4] = useState('0');
  const [radio5, setRadio5] = useState('0');

  // Define options for the radio group
  const radioOptions = [
    { value: '1', label: 'Strongly Agree' },
    { value: '2', label: 'Agree' },
    { value: '3', label: 'Strongly Disagree' },
    { value: '4', label: 'Disagree' },
    { value: '5', label: 'Neutral' },
  ];
  const radioOptionsYesNo = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },

  ];
  const handleRadio4Change = (event) => {
    setRadio4(event.target.value);
  };

  const handleRadio5Change = (event) => {
    setRadio5(event.target.value);
  };

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"Exit Interviews / Line Manager"}/>
      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <h5 className="card-title mx-3">Exit Interview for Wendy Ross by Sammy Marks</h5>

                  <div className="mx-3">

                    <div className="row">
                      <div className="col-md-8 align-self-start">

                        <div className="mb-3 mt-3">
                          <div className={"mb-2"}>What do you believe is the main reason for the employee leaving?</div>
                          <div className="custom-select">
                            <select className="custom-form-select" aria-label="Disabled select example" disabled>
                              <option value="option1">Remuneration - More Money</option>
                              <option value="option2">Option 2</option>
                              <option value="option3">Option 3</option>
                            </select>
                            <div className="custom-arrow"></div>
                          </div>
                        </div>

                        <div className={"mb-2"}>Do you agree with the following:</div>

                        <div className={"mb-3"}>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              Where you aware of the employee’s intention to leave?
                            </div>
                            <div className={"ms-5"}>
                              {radioOptionsYesNo.map((option) => (
                                  <div className="form-check form-check-inline" key={option.value}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio4"
                                        id={`radio4-${option.value}`}
                                        value={option.value}
                                        checked={radio4 === option.value}
                                        onChange={handleRadio4Change}
                                    />
                                    <label className="form-check-label" htmlFor={`radio4-${option.value}`}>
                                      {option.label}
                                    </label>
                                  </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              Do you regard this employee as a regrettable leaver?
                            </div>
                            <div className={"ms-5"}>
                              {radioOptionsYesNo.map((option) => (
                                  <div className="form-check form-check-inline" key={option.value}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio5"
                                        id={`radio5-${option.value}`}
                                        value={option.value}
                                        checked={radio5 === option.value}
                                        onChange={handleRadio5Change}
                                    />
                                    <label className="form-check-label" htmlFor={`radio5-${option.value}`}>
                                      {option.label}
                                    </label>
                                  </div>
                              ))}
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default LineManager;
