
import SignIn from './pages/SignIn';

import {BrowserRouter, MemoryRouter, Route, Routes} from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { Redirect } from './components/Redirect';
import { baseUrl, chatbotData } from './components/constants';
import axios from 'axios';
import Dashboard from './pages/Dashboard';
import Chatbot from './components/cf-react-chatbot/Chatbot';
import { useState } from 'react';
import Home from "./pages/Home";
import Exitee from "./pages/Questionnaires/Exitee";
import LineManager from "./pages/Questionnaires/LineManager";
import ExitInterviews from "./pages/ExitInterviews/ExitInterviews";
import HrFace2Face from "./pages/Questionnaires/HrFace2Face";
import Demographics from "./pages/Dashboard/Demographics";

import Culture from './pages/Culture/Culture';
import Engage from './pages/Engage/Engage';
import Develop from './pages/Develop/Develop';
import Grow from './pages/Grow/Grow';
import Reward from './pages/Reward/Reward';
import SystemLinks from './pages/SystemLinks/SystemLinks';
import config from './config';
import Insights from "./pages/Dashboard/Insights";
import IssueLogForm from './pages/IssueLog/IssueLogForm';
import IssueLogs from './pages/IssueLog/IssueLog';
import HelpSupport from "./pages/HelpSupport/HelpSupport";
import History from "./pages/ExitInterviews/History";
import InsightsFiltered from "./pages/Dashboard/InsightsFiltered";

const App = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [question, setQuestion] = useState();
  const token = localStorage.getItem("token");
  axios.defaults.baseURL = config.apiUrl;
  //axios.defaults.baseURL = "http://localhost:8000/api/";
  axios.defaults.baseURL = "https://cf-app.concisesolutions.co.za/api/";
  axios.interceptors.request.use(
    async (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.credentials = 'include'; // Set the "credentials" option to "include"
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(async (response) => {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // console.log(originalRequest)
    console.log(error)
    if (error.response.status === 401) { localStorage.removeItem('token'); window.location.reload(true); }
    if (error.response.status === 408) { localStorage.removeItem('token'); window.location.reload(true); }
    return Promise.reject(error);
  });

  const getConversation = () => {
    axios.get("getConversations").then((res) => {
      setConversation(res.data.data)
    }).catch(() => {

    })
  }
  const createQuestion = () => {
    axios.post("createQuestion", { question }).then((res) => {
      getConversation();
      setQuestion("");
    }).catch(() => {

    })
  }

  return (
    <>
      {(
        <button className="chatbot-toggler shadow">
          <span className="material-symbols-rounded">
            <div className="bot-icon-container" onClick={() => setShowChatbot((val) => !val)}>
              {showChatbot ? <i className="fa-solid fa-xmark text-light"></i> : <i className="fa-solid fa-message text-light"></i>}
            </div>
            {showChatbot && (
              <div className='position-absolute chatbot-package shadow rounded-4'>
                <Chatbot
                  data={chatbotData}
                  getConversation={getConversation}
                  conversation={conversation}
                  onChange={(value) => setQuestion(value)}
                  onClose={() => { }}
                  onSubmit={() => createQuestion()}
                />
              </div>
            )}
          </span>
        </button>
      )}
      <MemoryRouter>
        <Sidebar />
        <Routes>
          <Route exact path="/" element={<ProtectedRoutes element={<Home />} />} />
          <Route path="/exit-interviews" element={<ProtectedRoutes element={<ExitInterviews />} />} />
          <Route path="/exitee" element={<ProtectedRoutes element={<Exitee />} />} />
          <Route path="/line-manager" element={<ProtectedRoutes element={<LineManager />} />} />
          <Route path="/hr-questionnaire" element={<ProtectedRoutes element={<HrFace2Face />} />} />
          <Route path="/demographics" element={<ProtectedRoutes element={<Insights />} />} />
          <Route path="/insights" element={<ProtectedRoutes element={<Demographics />} />} />
          <Route path="/insights-filtered" element={<ProtectedRoutes element={<InsightsFiltered />} />} />
          <Route path="/help-support" element={<ProtectedRoutes element={<HelpSupport />} />} />
          <Route path="/history" element={<ProtectedRoutes element={<History />} />} />


          <Route exact path="/" element={<ProtectedRoutes element={<Dashboard />} />} />
          <Route path="/culture" element={<ProtectedRoutes element={<Culture />} />} />
          <Route path="/engage" element={<ProtectedRoutes element={<Engage />} />} />
          <Route path="/learn" element={<ProtectedRoutes element={<Develop />} />} />
          <Route path="/grow" element={<ProtectedRoutes element={<Grow />} />} />
          <Route path="/reward" element={<ProtectedRoutes element={<Reward />} />} />
          <Route path="/system-links" element={<ProtectedRoutes element={<SystemLinks />} />} />
          <Route path="/issue-logs" element={<ProtectedRoutes element={<IssueLogs />} />} />
          <Route path="/issue-logs/add" element={<ProtectedRoutes element={<IssueLogForm/>} />} />
          <Route path="/issue-logs/:id" element={<ProtectedRoutes element={<IssueLogForm/>} />} />
          <Route path="/login" element={<Redirect to="/" />} />
        </Routes>
      </MemoryRouter>
    </>
  );

}

export default App;
