import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const Exitee = () => {
  // Define a state variable to store the textarea content
  const [textareaValue, setTextareaValue] = useState('I applied to 3 different positions that become available in my area, but was never considered.');
  const [textareaValue2, setTextareaValue2] = useState('No.');
  const [radio1, setRadio1] = useState('4');
  const [radio2, setRadio2] = useState('3');
  const [radio3, setRadio3] = useState('4');
  const [radio4, setRadio4] = useState('0');


  // Define options for the radio group
  const radioOptions = [
    { value: '1', label: 'Strongly Agree' },
    { value: '2', label: 'Agree' },
    { value: '3', label: 'Strongly Disagree' },
    { value: '4', label: 'Disagree' },
    { value: '5', label: 'Neutral' },
  ];
  const radioOptionsYesNo = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },

  ];

  // Handle radio group change event
  const handleRadio1Change = (event) => {
    setRadio1(event.target.value);
  };
  const handleRadio2Change = (event) => {
    setRadio2(event.target.value);
  };
  const handleRadio3Change = (event) => {
    setRadio3(event.target.value);
  };
  const handleRadio4Change = (event) => {
    setRadio4(event.target.value);
  };

  // Handle the onChange event
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  // Handle the onChange event
  const handleTextareaChange2 = (event) => {
    setTextareaValue2(event.target.value);
  };


  return (
    <>
      <Header  icon={"fa-solid fa-users fa-xl mx-2"}  label={"Exit Interviews / Exitee Questionnaire"}/>
      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <h5 className="card-title mx-3">Exit Interview for Wendy Ross</h5>

                  <div className="mx-3">

                    <div className="row">
                      <div className="col-md-8 align-self-start">

                        <div className="mb-3 mt-3">
                          <div className={"mb-2"}>What is your secondary reason for leaving?</div>
                          <div className="custom-select">
                            <select className="custom-form-select" aria-label="Disabled select example" disabled>
                              <option value="option1">Career Opportunity - Promotion</option>
                              <option value="option2">Option 2</option>
                              <option value="option3">Option 3</option>
                            </select>
                            <div className="custom-arrow"></div>
                          </div>
                        </div>

                        <div className="mb-4 mt-3">
                          <div className={"mb-2"}>What is your main reason for leaving?</div>
                          <div className="custom-select">
                            <select className="custom-form-select" aria-label="Disabled select example" disabled>
                              <option value="option1">Working Conditions - Culture</option>
                              <option value="option2">Option 2</option>
                              <option value="option3">Option 3</option>
                            </select>
                            <div className="custom-arrow"></div>
                          </div>
                        </div>

                        <div className={"mb-2"}>Do you agree with the following:</div>

                        <div className={"mb-3"}>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>The organisation supported my growth and development.</div>
                            <div className={"ms-5"}>
                              {/*<div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                       value="option1" />
                                <label className="form-check-label" htmlFor="inlineRadio1">Strongly Agree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                       value="option2" />
                                <label className="form-check-label" htmlFor="inlineRadio2">Agree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                       value="option3" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Strongly Disagree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                       value="option3" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Disagree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                       value="option3" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Neutral</label>
                              </div>*/}


                              {radioOptions.map((option) => (
                                  <div className="form-check form-check-inline" key={option.value}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio1"
                                        id={`radio1-${option.value}`}
                                        value={option.value}
                                        checked={radio1 === option.value}
                                        onChange={handleRadio1Change}
                                    />
                                    <label className="form-check-label" htmlFor={`radio1-${option.value}`}>
                                      {option.label}
                                    </label>
                                  </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>

                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              My immediate manager and I agreed on a personal development plan and course of action for me each year.
                            </div>
                            <div className={"ms-5"}>
                              {/*<div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                       value="option1" />
                                <label className="form-check-label" htmlFor="inlineRadio1">Strongly Agree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                       value="option2" />
                                <label className="form-check-label" htmlFor="inlineRadio2">Agree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                       value="option3" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Strongly Disagree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                       value="option3" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Disagree</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                       value="option3" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Neutral</label>
                              </div>*/}

                              {radioOptions.map((option) => (
                                  <div className="form-check form-check-inline" key={option.value}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio2"
                                        id={`radio2-${option.value}`}
                                        value={option.value}
                                        checked={radio2 === option.value}
                                        onChange={handleRadio2Change}
                                    />
                                    <label className="form-check-label" htmlFor={`radio2-${option.value}`}>
                                      {option.label}
                                    </label>
                                  </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>

                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              The organisational culture is supportive.
                            </div>

                            <div className={"ms-5"}>
                              {radioOptions.map((option) => (
                                  <div className="form-check form-check-inline" key={option.value}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio3"
                                        id={`radio3-${option.value}`}
                                        value={option.value}
                                        checked={radio3 === option.value}
                                        onChange={handleRadio3Change}
                                    />
                                    <label className="form-check-label" htmlFor={`radio3-${option.value}`}>
                                      {option.label}
                                    </label>
                                  </div>
                              ))}
                            </div>

                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              Were there any issues in your work environment that impacted on performance? If so please elaborate.
                            </div>
                            <div className={"ms-5"}>
                              {radioOptionsYesNo.map((option) => (
                                  <div className="form-check form-check-inline" key={option.value}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio4"
                                        id={`radio4-${option.value}`}
                                        value={option.value}
                                        checked={radio4 === option.value}
                                        onChange={handleRadio4Change}
                                    />
                                    <label className="form-check-label" htmlFor={`radio4-${option.value}`}>
                                      {option.label}
                                    </label>
                                  </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              What could we have done to prevent your resignation / you from leaving?
                            </div>
                            <div className={"mt-2"}>
                              <textarea
                                  className="form-control"
                                  id="textarea1"
                                  rows="3"
                                  value={textareaValue}
                                  onChange={handleTextareaChange}
                                  disabled={true}
                              />
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              Is there any other information related to your decision to resign that you would like to share with us?
                            </div>
                            <div className={"mt-2"}>
                              <textarea
                                  className="form-control"
                                  id="textarea2"
                                  rows="3"
                                  value={textareaValue2}
                                  onChange={handleTextareaChange2}
                                  disabled={true}
                              />
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Exitee;
