import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";
import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const Insights = () => {
  const categories = [
    'Top Management',
    'Senior Management',
    'Professional / Middle Management',
    'Technical / Junior Management',
    'Semi-Skilled',
    'Unskilled',
  ];
  const data = [0, 2.2, 36.8, 59.9, 1.1, 0.0];

  const categories2 = [
    'Not Achieve',
    'Partially Achieved',
    'Fully Achieved',
    'Exceptionally Achieved',
    'Too Early to Assess',
  ];
  const data2 = [0, 10.8, 55.0, 8.2, 26.0];

  const categories3 = [
    '00 - 02',
    '03 - 05',
    '06 - 10',
    '11 - 20',
    '21 - 30',
    '31+',
  ];
  const data3 = [24.5, 26.0, 26.4, 13.4, 4.8, 4.8];

  const categories4 = [
    '18 - 24',
    '25 - 29',
    '30 - 39',
    '40 - 49',
    '50 - 59',
    '60+',
  ];
  const data4 = [3.0, 11.9, 42.0, 22.3, 11.2, 9.7];

  const categories5 = [
    'African',
    'Indian',
    'Coloured',
    'White',
  ];
  const data5 = [56.9, 8.2, 21.2, 13.8];

  const categories6 = [
    'Female',
    'Male',
  ];
  const data6 = [58.4, 41];

  const getOptions = (type, title, data, categories) => ({
    chart: {
      type,
      backgroundColor: 'white',
    },
    title: {
      text: `${title}`,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        // reserveSpace: false,
        //rotation: -27,
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: [
      {
        data: data,
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#6C9BBC',
      },
    ],
  });

  const colors = ['#6C9BBC', '#85C3D1'];

  const getOptions2 = (type, title, data, categories, colors) => ({
    chart: {
      type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: true
      }
    },
    colors: colors,
    title: {
      text: `${title}`,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: [
      {
        data: data,
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },

      },
    ],
  });

  return (
    <>
      <Header icon={"fa-solid fa-users"} label={"Dashboards / Demographics"} />
      <main id="main" className="main">
        <TempFilters />
        <section className="section ">
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>

                <div className='card-body'>

                  <HighchartsReact highcharts={Highcharts} options={getOptions('column', 'Exit by Employee', data, categories)} />

                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>

                <div className='card-body'>

                  <HighchartsReact highcharts={Highcharts} options={getOptions('column', 'Exits by Performance Rating', data2, categories2)} />

                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>

                <div className='card-body'>
                  <HighchartsReact highcharts={Highcharts} options={getOptions('column', 'Exit by Years of Service', data3, categories3)} />
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>

                <div className='card-body'>
                  <HighchartsReact highcharts={Highcharts} options={getOptions('column', 'Exit by Age', data4, categories4)} />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>

                <div className='card-body'>
                  <HighchartsReact highcharts={Highcharts} options={getOptions('column', 'Exit by Race', data5, categories5)} />
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>

                <div className='card-body'>
                  <HighchartsReact highcharts={Highcharts} options={getOptions2('column', 'Exit by Gender', data6, categories6, colors)} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Insights;
