import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const Insightsoriginal = () => {
  const categories = [
    'Opportunity for Growth and Development',
    'Relationship with immediate manager',
    'Loss of Confidence in Leadership',
    'Technical / Junior Management',
    'Relocation',
    'Working Conditions - Culture',
  ];
  const data = [52.4, 23.9, 12.3, 7.9, 3.5];
  const colors1 = [ '#85C3D1'];

  const categories2 = [
    'Working Conditions - Physical',
    'Remuneration - More Money',
    'Working Conditions - Physical',
    'Health Reasons',
    'Further Studies',
  ];
  const data2 = [46.9, 38.5, 7.2, 5.4, 2];

  const categories3 = [
    'Strongly Agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly Disagree',
  ];
  const data3 = [28, 47, 14, 7, 2, ];

  const categories4 = [
    'Strongly Agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly Disagree',
  ];
  const data4 = [42, 34, 14, 4, 6];

  const categories5 = [
    'Strongly Agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly Disagree',
  ];
  const data5 = [5, 23, 16, 48, 8];

  const categories6 = [
    'Strongly Agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly Disagree',
  ];
  const data6 = [8, 32, 18, 40, 2];

  const getOptions = (type, title, data, categories) => ({
    chart: {
      type,
      width: 500,
      height: 500,
      backgroundColor: 'white',
    },
    title: {
      text: `${title}`,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        // reserveSpace: false,
        //rotation: -27,
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: [
      {
        data: data,
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#6C9BBC',
      },
    ],
  });


  const colors = ['#769A50', '#769A50', '#85C3D1', '#6C9BBC', '#6C9BBC'];

  const getOptions2 = (type, title, data, categories, colors) => ({
    chart: {
      type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: true
      }
    },
    colors: colors,
    title: {
      text: `${title}`,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: [
      {
        data: data,
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },

      },
    ],
  });

  return (
    <>
      <section className="section ">
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <HighchartsReact highcharts={Highcharts} options={getOptions2('bar', 'Top 5 Primary Reasons for Leaving', data, categories, colors1)} />
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <HighchartsReact highcharts={Highcharts} options={getOptions2('bar', 'Top 5 Secondary Reasons for Leaving', data2, categories2, colors1)} />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <HighchartsReact highcharts={Highcharts} options={getOptions2('column', 'This organisation live up to its Brand Promise', data3, categories3, colors)} />
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <HighchartsReact highcharts={Highcharts} options={getOptions2('column', 'I would recommend this organisation as an employer', data4, categories4, colors)} />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>
              <div className='card-body'>
                <HighchartsReact highcharts={Highcharts} options={getOptions2('column', 'The organisational culture is supportive', data5, categories5, colors)} />
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='card'>

              <div className='card-body'>

                <HighchartsReact highcharts={Highcharts} options={getOptions2('column', 'The organisation supported my growth and development', data6, categories6, colors)} />

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Insightsoriginal;
