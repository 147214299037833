import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import {Link} from "react-router-dom";
import TempFilters from "../../components/Filters/TempFilters";

const ExitInterviews = () => {

  return (
    <>
        <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"Exit Interviews / Face to Face Interviews"}/>
      <main id="main" className="main">

        <section className="section ">
            <TempFilters/>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                    <div className="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th className={"bg-blue-grey"}>Personnel #</th>
                                <th className={"bg-blue-grey"}>Name</th>
                                <th className={"bg-light-blue"}>Last Name</th>
                                <th className={"bg-light-blue"}>Exit Date</th>
                                <th className={"bg-light-blue"}>Line Manager</th>
                                <th className={"bg-light-blue"}>Mandatory Face to Face Interview</th>
                                <th className={"bg-light-blue"}>Exitee Questionnaire</th>
                                <th className={"bg-light-blue"}>Line Manager Questionnaire</th>
                                <th className={"bg-light-blue"}>Face to Face Status</th>
                                <th className={"bg-light-blue"}>Open Questionnaire</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr>
                                <td>111222</td>
                                <td>Joe</td>
                                <td>Slovo</td>
                                <td>7 Oct 2023</td>
                                <td>Promise Jones</td>
                                <td>Yes</td>
                                <td>In Progress</td>
                                <td>
                                    <span>
                                        <b>
                                            <i>Complete</i>
                                        </b>
                                    </span>
                                </td>
                                <td>Not Started</td>
                                <td>
                                    <i className="fa-solid fa-folder-open"></i>
                                </td>
                            </tr>

                            <tr>
                                <td>999333</td>
                                <td>Anele</td>
                                <td>Swanepoel</td>
                                <td>11 Oct 2023</td>
                                <td>William May</td>
                                <td>Yes</td>
                                <td>Not Started</td>
                                <td>Not Started</td>
                                <td>Not Started</td>
                                <td>
                                    <i className="fa-solid fa-folder-open"></i>
                                </td>
                            </tr>

                            <tr>
                                <td>767676</td>
                                <td>Sam</td>
                                <td>Smith</td>
                                <td>2 Oct 2023</td>
                                <td>Mary Snow</td>
                                <td>Yes</td>
                                <td>In Progress</td>
                                <td>In Progress</td>
                                <td>Not Started</td>
                                <td>
                                    <i className="fa-solid fa-folder-open"></i>
                                </td>
                            </tr>

                            <tr>
                                <td>444222</td>
                                <td>Nick</td>
                                <td>White</td>
                                <td>13 Oct 2023</td>
                                <td>Henk Joe</td>
                                <td>Yes</td>
                                <td>In Progress</td>
                                <td>In Progress</td>
                                <td> Not Started</td>
                                <td>
                                    <i className="fa-solid fa-folder-open"></i>
                                </td>
                            </tr>

                            <tr>
                                <td>878787</td>
                                <td>Wendy</td>
                                <td>Ross</td>
                                <td>14 Oct 2023</td>
                                <td>Sammy Marks</td>
                                <td>Yes</td>
                                <td>
                                    <Link className={"dt-completed"} to={"/exitee"}>
                                        <b>
                                            <i>Complete</i>
                                        </b>
                                    </Link>
                                </td>
                                <td>
                                    <Link className={"dt-completed"} to={"/line-manager"}>
                                        <b>
                                            <i>Complete</i>
                                        </b>
                                    </Link>
                                </td>
                                <td>
                                    <Link className={"dt-completed"} to={"/hr-questionnaire"}>
                                        <b>
                                            <i>Not Started - Open</i>
                                        </b>
                                    </Link>
                                </td>
                                <td>
                                    <i className="fa-solid fa-folder-open"></i>
                                </td>
                            </tr>

                            <tr>
                                <td>101010</td>
                                <td>Mandy</td>
                                <td>Norway</td>
                                <td>9 Oct 2023</td>
                                <td>Marilyn Thomas</td>
                                <td>No</td>
                                <td>
                                    <span>
                                        <b>
                                            <i>Complete</i>
                                        </b>
                                    </span>
                                </td>
                                <td>Not Started</td>
                                <td>Not Started - Open</td>
                                <td>
                                    <i className="fa-solid fa-folder-open"></i>
                                </td>
                            </tr>

                            <tr>
                                <td>323232</td>
                                <td>Jack</td>
                                <td>Jones</td>
                                <td>11 Oct 2023</td>
                                <td>Joel Smith</td>
                                <td>No</td>
                                <td>
                                    <span>
                                        <b>
                                            <i>Complete</i>
                                        </b>
                                    </span>
                                </td>
                                <td>In Progress</td>
                                <td>Not Started - Open</td>
                                <td>
                                    <i className="fa-solid fa-folder-open"></i>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default ExitInterviews;
